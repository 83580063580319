import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
	stage: string;
	openId: string;
	props: any;
}

const initialState: ModalState = {
	stage: '',
	openId: '',
	props: {},
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		showModal: (
			state,
			action: PayloadAction<{
				openId: string;
				props?: any;
			}>
		) => {
			state.openId = action.payload.openId;
			state.props = action.payload.props || {};
		},
		closeModal: (state) => {
			state.openId = '';
			state.props = {};
		},
	},
});

export const { showModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
