import React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from 'styled-components';

export default function Avatar(props) {
	const { children, className, sx = {} } = props;

	return (
		<MuiAvatar className={className} sx={sx}>
			{children}
		</MuiAvatar>
	);
}
