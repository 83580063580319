import React, { useEffect } from 'react';
import ScreenContainer from '../../common/layout/ScreenContainer';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { fetchClient } from '../../../data/thunks/clientThunk';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import BusySpinner from '../../common/BusySpinner';
import dayjs from 'dayjs';
import Paper, { PaperHeader, PaperHeaderNav } from '../../common/layout/Paper';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { styled } from 'styled-components';
import ClientContactsTable from './ClientContactsTable';
import AddClientContactModal from './AddClientContactModal';

export default function ClientDetailsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const match = useMatch('clients/:clientId/*');
	const clientId = match?.params?.clientId;

	const { client } = useSelector((state: RootState) => state.clientState);

	useEffect(() => {
		if (clientId && clientId !== client.id) {
			dispatch(fetchClient(clientId));
		}
	}, [clientId, client.id, dispatch]);

	if (clientId !== client.id) {
		return <BusySpinner />;
	}

	return (
		<>
			<ScreenBusySpinner />
			<AddClientContactModal />
			<StyledContainer>
				<StyledPaper>
					<PaperHeader>
						<PaperHeaderNav onClick={() => navigate('/clients')}>
							Clients <ChevronRightOutlinedIcon />
						</PaperHeaderNav>
						<ClientName>{client.name.toUpperCase()}</ClientName>
					</PaperHeader>
					<div>
						Created: {dayjs(client.created).format('MMM D, YYYY')}
					</div>
					<div>Created by: {client.createdBy?.name}</div>
				</StyledPaper>
				<ClientContactsTable />
			</StyledContainer>
		</>
	);
}

const StyledPaper = styled(Paper)`
	padding: 9px 20px 20px 10px;
	margin-bottom: 20px;
`;

const ClientName = styled.div`
	display: inline;
	margin-right: 20px;
	color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	height: 100%;
`;
