import { DatedUserReference } from './user';

export interface Client {
	id: string;
	name: string;
	projects: any[];
	contacts: ClientContact[];
	created: string;
	updated: string;
	createdBy: DatedUserReference;
}

export interface Clients {
	list: Client[];
	count: number;
	filter: {
		searchText: string;
		page: number;
		pageSize: number;
		sortModel: any;
		where: object;
	};
	initialized: boolean;
}

export interface ClientDTO {
	name: string;
}

export interface ClientsFilter {
	searchText?: string;
	order?: string;
	direction?: string;
	page?: number;
	take?: number;
	where?: any;
}

export interface ClientContact {
	uuid: string;
	firstName: string;
	lastName: string;
	title: string;
	emailContacts: ContactDetails[];
	phoneContacts: ContactDetails[];
	created: Date;
}

export interface ClientContactDTO {
	clientId: string;
	firstName: string;
	lastName: string;
	title: string;
	emailContacts: ContactDetails[];
	phoneContacts: ContactDetails[];
}

export interface ContactDetails {
	uuid: string;
	type: 'MOBILE' | 'LANDLINE' | 'FAX' | 'EMAIL' | 'OTHER';
	value: string;
}

export const PhoneTypes = {
	MOBILE: {
		id: 'MOBILE',
		display: 'Mobile',
	},
	LANDLINE: {
		id: 'LANDLINE',
		display: 'Landline',
	},
	FAX: {
		id: 'FAX',
		display: 'Fax',
	},
	OTHER: {
		id: 'OTHER',
		display: 'Other',
	},
};

export const getPhoneTypes = () => {
	return Object.keys(PhoneTypes).map((key) => PhoneTypes[key]);
};
