export interface User {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	role: string;
	alwaysAddToProjects: boolean;
	created: string;
	updated: string;
	acceptedInvite: boolean;
	acceptedTermsAndConditions: boolean;
	acceptedTermsAndConditionsDate: string;
	googleId: string;
	preferences: UserPreferences;
}

export interface UserDTO {
	email: string;
	firstName: string;
	lastName: string;
	googleId: string;
}

export interface UserPreferences {
	theme?: 'light' | 'dark' | 'system';
}

export interface UserUpdateDTO {
	id: string;
	firstName?: string;
	lastName?: string;
	role?: string;
	alwaysAddToProjects?: boolean;
}

export interface UpdateSelfDTO {
	firstName?: string;
	lastName?: string;
	preferences?: UserPreferences;
	acceptedTermsAndConditions?: boolean;
	acceptedTermsAndConditionsDate?: Date;
}

export interface GoogleUser {
	name: {
		givenName: string;
		familyName: string;
	};
	primaryEmail: string;
	id: string;
	creationTime: string;
}

export interface DatedUserReference {
	id: string;
	name: string;
	date: string;
}

export interface UsersFilter {
	searchText?: string;
	order?: string;
	direction?: string;
	page?: number;
	take?: number;
	where?: any;
}

export const UserRoles = {
	ADMIN: {
		id: 'ADMIN',
		display: 'Admin',
	},
	CREW: {
		id: 'CREW',
		display: 'Crew',
	},
};

export const UserRolesDDOptions = Object.values(UserRoles);
