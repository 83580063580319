import React, { useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import { Project, projectTypesOptions } from '../../../data/models/project';
import ModalHeader from '../../common/ModalHeader';
import { useDispatch } from 'react-redux';
import {
	asyncCallDone,
	asyncCallStart,
	refreshProjectSuccess,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
import Autocomplete, {
	AutocompleteOption,
} from '../../common/form/AutoComplete';
import { updateProject } from '../../../services/project-service';

interface ProjectChangeTypeProps {
	open: boolean;
	handleClose: () => void;
	project: Project;
}
export default function ProjectChangeType(props: ProjectChangeTypeProps) {
	const { open, handleClose, project } = props;

	const dispatch = useDispatch();
	const [type, setType] = useState<AutocompleteOption | null>(null);

	const projectTypeOptions = projectTypesOptions();

	const handleChangeType = async () => {
		if (!type) return;
		try {
			handleClose();
			dispatch(asyncCallStart());
			const updatedProject = await updateProject(project.id, {
				type: type.id,
			});
			dispatch(refreshProjectSuccess(updatedProject));
			dispatch(
				showAlert({
					message: 'Successfully changed the type.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred changing the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ModalHeader>Change Type</ModalHeader>
			<Label>Name</Label>
			<Info>{project.namespace}</Info>
			<Autocomplete
				id={'type'}
				label={'Type'}
				value={type}
				options={projectTypeOptions}
				onChange={(e, value) => {
					setType(value);
				}}
			/>
			<SaveCancelContainer>
				<SaveCancelButtons
					handleSave={handleChangeType}
					handleCancel={handleClose}
					saveText={'Update'}
					saveDisabled={!type}
				/>
			</SaveCancelContainer>
		</Modal>
	);
}

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
