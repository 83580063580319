import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import store from '../data/store';
import Config from '../config';
import { logout } from '../data/stores/authStore';
// import { logout } from './actions/authActions';

const defaultConfig: AxiosRequestConfig = {
	baseURL: Config.apiBaseUrl,
	timeout: 90000,
};

export default async function axiosClient(
	config: AxiosRequestConfig = defaultConfig
): Promise<AxiosResponse> {
	const storeState = store && store.getState();
	const token = storeState.authState.astroToken || '';
	// if (!token) {
	// 	store.dispatch(logout());
	// 	return Promise.reject('No token found');
	// }
	if (!defaultConfig.headers) {
		defaultConfig.headers = { authorization: `Bearer ${token}` };
	} else {
		defaultConfig.headers.authorization = `Bearer ${token}`;
	}
	const response: AxiosResponse = await axios({
		...defaultConfig,
		...config,
	}).catch((error) => {
		// Throw graphql message if available
		if (error.response?.data?.code === 403) {
			// Log user out on 403 FORBIDDEN
			// Error 403 should only happen when token is expired
			store.dispatch(logout());
		}
		throw error.response?.data?.message || error.message || error;
	});

	const errors = response.data?.errors || [];
	if (errors.length) {
		if (errors[0].message.includes('Token error:')) {
			store.dispatch(logout());
		}
		throw errors[0].message || 'An error occurred';
	}

	if (!!response.data) {
		return response.data;
	}

	return response;
}

export async function baseAxiosClient(
	config: AxiosRequestConfig = defaultConfig
): Promise<any> {
	try {
		const response: AxiosResponse = await axios({
			...config,
		});

		if (!!response.data) {
			return response.data;
		}

		return response;
	} catch (error: any) {
		console.error(error);
		throw error.response?.data?.message || error.message || error;
	}
}
