import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

interface Option {
	id: string;
	display: string;
}

interface SelectProps {
	label: string;
	value: string;
	onChange: (event: SelectChangeEvent) => void;
	options: Option[];
	fullWidth?: boolean;
	minWidth?: number;
	disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
	label,
	value,
	onChange,
	options,
	fullWidth = true,
	minWidth = 120,
	disabled = false,
	...rest
}) => {
	return (
		<Box sx={{ minWidth }}>
			<FormControl fullWidth={fullWidth} disabled={disabled}>
				<InputLabel id={`${label}-label`} shrink>
					{label}
				</InputLabel>
				<MuiSelect
					labelId={`${label}-label`}
					id={`${label}-select`}
					value={value}
					label={label}
					onChange={onChange}
					size="small"
					{...rest}
				>
					{options.map((option) => (
						<MenuItem key={option.id} value={option.id}>
							{option.display}
						</MenuItem>
					))}
				</MuiSelect>
			</FormControl>
		</Box>
	);
};

export default Select;
