import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import Table from '../common/Table';
import Link from '../common/Link';
import GoogleDriveIcon from '../../images/google-drive.png';
import { styled } from 'styled-components';
import { fetchTemplates } from '../../data/thunks/templateThunk';
import { getIconFromGoogleMimeType } from '../../utils/company-utils';

export default function TemplatesTable(props) {
	const dispatch: AppDispatch = useDispatch();

	const { handleRowClick } = props;

	const {
		templates: { list, count, initialized },
	} = useSelector((state: RootState) => state.templateState);

	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		dispatch(fetchTemplates());
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
	};

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 350,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					const icon = getIconFromGoogleMimeType(params.row.mimeType);
					return (
						<NameContainer>
							<img
								style={{
									height: 20,
									marginRight: 10,
									objectFit: 'cover',
									cursor: 'pointer',
								}}
								src={icon.icon}
								alt={icon.alt}
							/>
							<Link onClick={() => handleRowClick(params.row)}>
								{params.value}
							</Link>
						</NameContainer>
					);
				},
			},
			{
				field: 'version',
				headerName: 'Version',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
			},
			{
				field: 'updated',
				headerName: 'Last modified',
				minWidth: 120,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, YYYY'),
			},
			{
				field: 'fileSize',
				headerName: 'File size',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					// Convert bytes to KB by dividing by 1024
					`${(params.value / 1024).toFixed(2)} KB`,
			},
			{
				field: 'links',
				headerName: '',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<IconContainer>
							<img
								style={{
									height: 20,
									objectFit: 'cover',
									cursor: 'pointer',
								}}
								src={GoogleDriveIcon}
								alt={'Google Drive'}
								onClick={() => {
									window.open(
										`https://drive.google.com/file/d/${params.row.link}/view?usp=drivesdk`,
										'_blank'
									);
								}}
							/>
						</IconContainer>
					);
				},
			},
		];
	}, [handleRowClick]);

	const rows: any[] = useMemo(() => {
		const filteredList = !!searchText
			? list.filter((template) =>
					template.name
						.toLowerCase()
						.includes(searchText.toLowerCase())
			  )
			: list;
		return filteredList.map((template) => {
			const latestVersion = template.versions.sort(
				(a, b) => b.version - a.version
			)[0];
			return {
				...template,
				version: latestVersion.version,
				link: latestVersion.googleId,
			};
		});
	}, [list, searchText]);

	return (
		<Table
			title={'Templates'}
			columns={columns}
			rows={rows}
			page={0}
			pageSize={50}
			rowCount={count}
			sortModel={[
				{
					field: 'created',
					sort: 'desc',
				},
			]}
			onSortModelChange={() => {}}
			searchText={searchText}
			handleTextSearch={handleTextSearch}
			onPaginationModelChange={() => {}}
			loading={!initialized}
		/>
	);
}

const NameContainer = styled.div`
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 15px;
`;
