import React, { useEffect, useMemo } from 'react';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import Button from '../common/buttons/Button';
import { styled } from 'styled-components';
import { SubHeader } from '../common/layout/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Grid } from '../common/layout/Content';
import Link from '../common/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import { getQuickLinkIconFromUrl } from '../../utils/company-utils';
import { fetchCompany } from '../../data/thunks/companyThunk';
import BusySpinner from '../common/BusySpinner';

const QuickLinks = () => {
	const dispatch = useDispatch<AppDispatch>();

	const {
		company: { quickLinks, initialized },
	} = useSelector((state: RootState) => state.companyState);

	useEffect(() => {
		dispatch(fetchCompany());
	}, []);

	return (
		<Paper>
			<PaperHeader>QuickLinks</PaperHeader>
			<QuickLinksContainer>
				{initialized ? (
					<Links links={quickLinks} />
				) : (
					<BusySpinner display={'component'} />
				)}
			</QuickLinksContainer>
		</Paper>
	);
};

const QuickLinksContainer = styled.div`
	padding: 20px;
`;

export default QuickLinks;

const Links = ({ links }) => {
	if (!links.length) {
		return <NotInitialized />;
	}

	return (
		<Grid container spacing={3}>
			{links.map((link) => (
				<Grid item xs={3} md={3} key={link.id}>
					<Paper border>
						<Container>
							<ItemContainer>
								{getIcon(link.url)}
								<div>{link.title}</div>
							</ItemContainer>
							<ItemContainer>
								<Button
									onClick={() =>
										window.open(link.url, '_blank')
									}
									variant={'text'}
									sz={'small'}
									startIcon={<OpenInNewIcon />}
								>
									OPEN
								</Button>
							</ItemContainer>
						</Container>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};

const Container = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
`;

const ItemContainer = styled.div`
	display: flex;
	gap: 8px;
	justify-content: left;
	align-items: left;
`;

const getIcon = (url) => {
	const quickLinkIcon = getQuickLinkIconFromUrl(url);
	return (
		<img
			style={{ height: 20, objectFit: 'cover' }}
			src={quickLinkIcon.icon}
			alt={quickLinkIcon.alt}
		/>
	);
};

const NotInitialized = () => {
	const navigate = useNavigate();

	return (
		<NotInitializedContainer>
			<SubHeader>Quick Links have not been set up</SubHeader>
			<Button onClick={() => navigate('/company')}>
				ADD QUICK LINKS
			</Button>
		</NotInitializedContainer>
	);
};

const NotInitializedContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 40px;
`;
