import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template, Templates } from '../models/template';
import { AsyncStage } from '../reduxCommon';

interface TemplateState {
	stage: AsyncStage;
	templates: Templates;
	error: any;
}

const initialState: TemplateState = {
	stage: AsyncStage.NoOp,
	templates: {
		list: [],
		count: 0,
		filter: {
			searchText: '',
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'created', sort: 'desc' }],
			where: {},
		},
		initialized: false,
	},
	error: '',
};

export const templateSlice = createSlice({
	name: 'Template',
	initialState,
	reducers: {
		asyncCallStart: (state: TemplateState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: TemplateState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: TemplateState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		refreshTemplates: (
			state,
			action: PayloadAction<{
				list: Template[];
				count: number;
			}>
		) => {
			state.templates = {
				...state.templates,
				list: action.payload.list,
				count: action.payload.count,
				initialized: true,
			};
		},
		hideTemplate: (state) => {
			// state.open = false;
			// state.message = '';
			// state.severity = 'info';
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshTemplates,
	hideTemplate,
} = templateSlice.actions;

export default templateSlice.reducer;
