import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as CompanyService from '../../services/company-service';
import { showAlert } from '../stores/alertStore';
import {
	asyncCallDone,
	asyncCallError,
	asyncCallStart,
	refreshTemplates,
} from '../stores/templateStore';

export const fetchTemplates = (): AppThunk => async (dispatch: Dispatch) => {
	dispatch(asyncCallStart());
	try {
		const response = await CompanyService.fetchTemplates();
		dispatch(refreshTemplates(response));
	} catch (error) {
		dispatch(asyncCallError(error));
		return dispatch(
			showAlert({
				message: 'An error occurred while fetching the Templates',
				severity: 'error',
			})
		);
	}
	dispatch(asyncCallDone());
};
