import React, { useEffect, useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import { createProject } from '../../../services/project-service';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useClientsList from '../../../hooks/useClientsList';
import useUsersList from '../../../hooks/useUsersList';
import { Controller, useForm } from 'react-hook-form';
import {
	asyncCallDone,
	asyncCallStart,
} from '../../../data/stores/projectStore';
import { ProjectDTO, projectTypesOptions } from '../../../data/models/project';
import { showAlert } from '../../../data/stores/alertStore';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import Input from '../../common/form/Input';
import Autocomplete from '../../common/form/AutoComplete';
import { AsyncStage } from '../../../data/reduxCommon';
import ProjectCreationConfirmationModal from './ProjectCreationConfirmationModal';
import Link from '../../common/Link';
import ModalHeader from '../../common/ModalHeader';
import { closeModal } from '../../../data/stores/modalStore';

export default function ProjectCreationModal() {
	const {
		openId,
		props: { clientId },
	} = useSelector((state: RootState) => state.modalState);

	const open = openId === 'projectCreationModal';

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { stage } = useSelector((state: RootState) => state.projectState);

	const { dropdownOptions: clientsDropDownOptions } = useClientsList();
	const { dropdownOptionsFullData: usersDropDownOptions } = useUsersList();
	const projectTypeOptions = projectTypesOptions();

	const [modalOpen, setModalOpen] = useState(false);
	const [previewData, setPreviewData] = useState<any>();

	type FormValues = {
		client: string;
		name: string;
		type: string;
		workspaceMembers: { id: string; display: string; email: string }[];
	};
	const { handleSubmit, control, reset, formState, setValue } =
		useForm<FormValues>({
			defaultValues: {
				client: clientId || '',
				name: '',
				workspaceMembers: [],
			},
			mode: 'onSubmit',
		});

	const { errors } = formState;

	useEffect(() => {
		const defaultMembers: { id: string; display: string; email: string }[] =
			[];
		usersDropDownOptions.forEach((user) => {
			if (user.alwaysAddToProjects) {
				defaultMembers.push({
					id: user.id,
					email: user.email,
					display: `${user.firstName} ${user.lastName}`,
				});
			}
		});
		setValue('workspaceMembers', defaultMembers);
	}, [usersDropDownOptions, open]);

	const resetForm = () => {
		reset();
	};

	const handlePreview = (data) => {
		const client = clientsDropDownOptions.find(
			(client) => client.id === data.client
		);
		setPreviewData({
			client: client?.display,
			name: data.name,
			type: data.type,
			workspaceMembers: data.workspaceMembers,
		});
		setModalOpen(true);
	};

	const handleCreateProject = async (data) => {
		try {
			setModalOpen(false);
			dispatch(asyncCallStart());
			const createProjectDTO: ProjectDTO = {
				name: data.name,
				clientId: data.client,
				type: data.type,
				workspaceMembers: data.workspaceMembers.map(
					(member) => member.id
				),
			};
			const createdProject = await createProject(createProjectDTO);
			navigate(`/projects/${createdProject.id}`);
			handleClose();
			dispatch(
				showAlert({
					message:
						'We recieved your request! Please allow a few minutes for the project to be created.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred creating the project.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	const handleCreateNewClient = () => {
		dispatch(closeModal());
		navigate('/clients');
	};

	const handleClose = () => {
		dispatch(closeModal());
		resetForm();
	};

	return (
		<>
			<ProjectCreationConfirmationModal
				open={modalOpen}
				handleClose={() => setModalOpen(false)}
				handleSave={handleSubmit(handleCreateProject)}
				previewData={previewData}
			/>
			<Modal open={open} handleClose={handleClose}>
				{open ? (
					<>
						<ScreenBusySpinner />
						<FormContainer>
							<ModalHeader>Create New Project</ModalHeader>
							<InputContainer>
								<Controller
									name={'name'}
									control={control}
									rules={{ required: 'Name is required' }}
									render={({ field }) => (
										<Input
											{...field}
											label={'Project Name'}
											id="project-name"
											error={!!errors.name}
											helperText={errors.name?.message}
										/>
									)}
								/>
								<div>
									<Controller
										name={'client'}
										control={control}
										rules={{
											required: 'Client is required',
										}}
										render={({ field }) => (
											<Autocomplete
												{...field}
												id={'client'}
												label={'Client'}
												options={clientsDropDownOptions}
												onChange={(e, value) => {
													field.onChange(
														value?.id || ''
													);
												}}
												error={!!errors.client}
												helperText={
													errors.client?.message
												}
											/>
										)}
									/>
									<LinkContainer>
										<Link onClick={handleCreateNewClient}>
											Don’t see your client? Click here to
											add a new client.
										</Link>
									</LinkContainer>
								</div>
								<Controller
									name={'type'}
									control={control}
									rules={{
										required: 'Project Type is required',
									}}
									render={({ field }) => (
										<Autocomplete
											{...field}
											id={'type'}
											label={'Project Type'}
											options={projectTypeOptions}
											onChange={(e, value) => {
												field.onChange(value?.id || '');
											}}
											error={!!errors.type}
											helperText={errors.type?.message}
										/>
									)}
								/>
								<Controller
									name={'workspaceMembers'}
									control={control}
									rules={{ required: 'Members are required' }}
									render={({ field }) => (
										<Autocomplete
											{...field}
											id={'workspaceMembers'}
											label={'Workspace Members'}
											options={usersDropDownOptions}
											onChange={(e, value) => {
												field.onChange(value);
											}}
											error={!!errors.workspaceMembers}
											helperText={
												errors.workspaceMembers?.message
											}
											multiple
										/>
									)}
								/>
								<br />
								<SaveCancelButtons
									handleSave={handleSubmit(handlePreview)}
									handleCancel={handleClose}
									saveText={'Review'}
									saveDisabled={stage === AsyncStage.Busy}
									cancelDisabled={stage === AsyncStage.Busy}
								/>
							</InputContainer>
						</FormContainer>
					</>
				) : (
					<></>
				)}
			</Modal>
		</>
	);
}

const LinkContainer = styled.div`
	font-size: 12px;
	text-align: left;
	margin-top: 5px;
`;

const FormContainer = styled.div`
	text-align: center;
`;

const InputContainer = styled.div`
	max-width: 450px;
	display: flex;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
`;
