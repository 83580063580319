export interface Template {
	id: string;
	name: string;
	description: string;
	created: Date;
	updated: Date;
	mimeType: string;
	fileSize: number;
	versions: TemplateVersion[];
	active: boolean;
}

export interface Templates {
	list: Template[];
	count: number;
	filter: {
		searchText: string;
		page: number;
		pageSize: number;
		sortModel: any;
		where: object;
	};
	initialized: boolean;
}

export interface CompanyTemplate {
	created: Date;
	updated: Date;
	templateId: string;
	activeVersion: TemplateVersion;
	active: boolean;
}

export interface TemplateVersion {
	version: number;
	googleId: string;
}

export function getReadableGoogleMimeType(mimeType: string): string {
	const mimeTypeMap: { [key: string]: string } = {
		'application/vnd.google-apps.document': 'Google Docs',
		'application/vnd.google-apps.spreadsheet': 'Google Sheets',
		'application/vnd.google-apps.presentation': 'Google Slides',
		'application/vnd.google-apps.drawing': 'Google Drawings',
		'application/vnd.google-apps.form': 'Google Forms',
		'application/vnd.google-apps.script': 'Google Apps Script',
		'application/vnd.google-apps.map': 'Google My Maps',
		'application/vnd.google-apps.site': 'Google Sites',
		'application/vnd.google-apps.folder': 'Google Drive Folder',
		'application/vnd.google-apps.shortcut': 'Google Drive Shortcut',
		'application/vnd.google-apps.photo': 'Google Photos',
		'application/vnd.google-apps.video': 'Google Videos',
		'application/vnd.google-apps.audio': 'Google Audio',
		'application/vnd.google-apps.file': 'Google Drive File',
		'application/vnd.google-apps.unknown': 'Unknown Google Drive File',
		'application/vnd.google-apps.fusiontable': 'Google Fusion Table',
		'application/vnd.google-apps.jam': 'Google Jamboard',
		'application/vnd.google-apps.drive-sdk': 'Third-party App File',
	};

	return mimeTypeMap[mimeType] || 'Unknown Google App Type';
}
