import { Paper as MuiPaper } from '@mui/material';
import { styled } from 'styled-components';

interface PaperProps {
	variant?: 'elevation' | 'outlined';
	style?: any;
	children: React.ReactNode;
	className?: string;
	border?: boolean;
}
const Paper = (props: PaperProps) => {
	const {
		children,
		variant = 'outlined',
		style = {},
		className,
		border,
	} = props;
	return (
		<MuiPaper
			sx={(theme) => ({
				borderColor: border ? theme.palette.divider : 'transparent',
				fontSize: '0.875rem',
				position: 'relative',
				borderRadius: 2,
			})}
			style={{ ...style }}
			variant={variant}
			className={className}
		>
			{children}
		</MuiPaper>
	);
};

export default Paper;

export const PaperHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;

export const PaperHeaderNav = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
