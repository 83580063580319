import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Modal from '../../common/Modal';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Grid, IconButton, Tooltip } from '@mui/material';
import Input from '../../common/form/Input';
import ModalHeader from '../../common/ModalHeader';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { closeModal } from '../../../data/stores/modalStore';
import { ClientContact, getPhoneTypes } from '../../../data/models/client';
import TextMask from '../../common/form/MaskInput';
import Paper from '../../common/layout/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuid } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import Select from '../../common/form/Select';
import {
	createClientContact,
	updateClientContact,
} from '../../../services/client-service';
import {
	asyncCallDone,
	asyncCallStart,
	refreshClientSuccess,
} from '../../../data/stores/clientStore';
import { showAlert } from '../../../data/stores/alertStore';
import { AsyncStage } from '../../../data/reduxCommon';

export default function AddClientContactModal() {
	const {
		openId,
		props: { contact },
	} = useSelector((state: RootState) => state.modalState);
	const {
		client: { id: clientId },
		stage,
	} = useSelector((state: RootState) => state.clientState);

	const open = openId === 'addClientContactModal';

	const dispatch = useDispatch<AppDispatch>();

	const defaultValues: Partial<ClientContact> = {
		firstName: '',
		lastName: '',
		title: '',
		phoneContacts: [
			{
				value: '',
				type: 'MOBILE',
				uuid: uuid(),
			},
		],
		emailContacts: [],
	};

	const { handleSubmit, control, reset, formState } = useForm({
		defaultValues,
		mode: 'onBlur',
	});
	const { errors } = formState;

	const resetForm = (contact?: ClientContact) => {
		reset({
			firstName: contact?.firstName || '',
			lastName: contact?.lastName || '',
			title: contact?.title || '',
			phoneContacts: contact?.phoneContacts?.length
				? contact?.phoneContacts
				: [
						{
							value: '',
							type: 'MOBILE',
						},
				  ],
			emailContacts: contact?.emailContacts?.length
				? contact?.emailContacts
				: [
						{
							value: '',
							type: 'EMAIL',
						},
				  ],
		});
	};

	useEffect(() => {
		if (contact) {
			resetForm(contact);
		}
	}, [contact]);

	const handleClose = () => {
		dispatch(closeModal());
		resetForm();
	};

	const handleOnSaveClick = async (data: Partial<ClientContact>) => {
		dispatch(asyncCallStart());
		try {
			const contactDTO = {
				...data,
				clientId,
			};
			if (contact?.uuid) {
				contactDTO.uuid = contact.uuid;
			}
			const updatedClient = contact
				? // @ts-ignore
				  await updateClientContact(contactDTO)
				: // @ts-ignore
				  await createClientContact(contactDTO);
			dispatch(refreshClientSuccess(updatedClient));
			handleClose();
		} catch (error) {
			console.error(error);
			dispatch(
				showAlert({
					message: 'An error occurred while adding a Contact',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

	const handleCancelCloseOnClick = () => {
		handleClose();
	};

	if (!open) return <></>;

	return (
		<Modal open={open} handleClose={handleCancelCloseOnClick}>
			<ModalHeader>
				{!!contact ? 'Edit' : 'Add'} Client Contact
			</ModalHeader>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Controller
							name={'firstName'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									{...field}
									id={'firstName'}
									label={'First Name'}
									onChange={(e) => {
										e.target.value =
											e.target.value
												.charAt(0)
												.toUpperCase() +
											e.target.value.slice(1);
										field.onChange(e);
									}}
									fullWidth
									error={errors.firstName}
									helperText={errors.firstName?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Controller
							name={'lastName'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									{...field}
									id={'lastName'}
									label={'Last Name'}
									fullWidth
									error={errors.lastName}
									helperText={errors.lastName?.message}
									onChange={(e) => {
										e.target.value =
											e.target.value
												.charAt(0)
												.toUpperCase() +
											e.target.value.slice(1);
										field.onChange(e);
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name={'title'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									{...field}
									label={'Title'}
									fullWidth
									error={errors.title}
									helperText={errors.title?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<PhoneContacts control={control} errors={errors} />
					</Grid>
					<NoTopMarginGrid item xs={12} md={12}>
						<EmailContacts control={control} errors={errors} />
					</NoTopMarginGrid>
				</Grid>
				<ButtonContainer>
					<SaveCancelButtons
						handleSave={handleSubmit(handleOnSaveClick)}
						handleCancel={handleCancelCloseOnClick}
						saveDisabled={stage === AsyncStage.Busy}
						cancelDisabled={stage === AsyncStage.Busy}
					/>
				</ButtonContainer>
			</Container>
		</Modal>
	);
}

export const PhoneContacts = (props) => {
	const { control, errors, name = 'phoneContacts' } = props;

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const handleAddPhoneContact = () => {
		append({
			value: '',
			type: 'MOBILE',
			uuid: uuid(),
		});
	};

	const phoneTypes = useMemo(() => getPhoneTypes(), []);

	if (!fields) {
		return <></>;
	}

	return (
		<PhoneContactsContainer>
			<Header>
				Phone{' '}
				<Tooltip title={'Add Phone'}>
					<PlusButton
						onClick={handleAddPhoneContact}
						color={'primary'}
					>
						<AddIcon fontSize={'small'} />
					</PlusButton>
				</Tooltip>
			</Header>
			{fields.map((fullField, index) => (
				<PhoneContactContainer key={index}>
					<StyledGridContainer container spacing={2}>
						<Grid item xs={6} md={6}>
							<Controller
								name={`${name}.${index}.value`}
								control={control}
								rules={{
									validate: (value) =>
										!value ||
										/^(\d{10})$/.test(
											value.replace(/\D/g, '')
										) ||
										'Phone number should be 10 digits long.',
								}}
								render={({ field }) => {
									return (
										<TextMask
											{...field}
											id={`${name}.${index}.value`}
											name={'phone'}
											label={'Number'}
											error={
												!!errors?.phoneContacts?.[index]
											}
											helperText={
												errors?.phoneContacts?.[index]
													?.message
											}
										/>
									);
								}}
							/>
						</Grid>
						<Grid item xs={6} md={6}>
							<Controller
								name={`${name}.${index}.type`}
								control={control}
								rules={{ required: true }}
								render={({ field }) => {
									return (
										<Select
											{...field}
											label={'Type'}
											options={phoneTypes}
										/>
									);
								}}
							/>
						</Grid>
						<Grid item md={1}>
							{index !== 0 && (
								<DeleteButton
									color={'warning'}
									onClick={() => remove(index)}
									aria-label="delete"
								>
									<DeleteIcon />
								</DeleteButton>
							)}
						</Grid>
					</StyledGridContainer>
				</PhoneContactContainer>
			))}
		</PhoneContactsContainer>
	);
};

const EmailContacts = (props) => {
	const { control, errors, name = 'emailContacts' } = props;

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const handleAddEmailContact = () => {
		append({
			value: '',
			type: 'EMAIL',
			uuid: uuid(),
		});
	};

	return (
		<PhoneContactsContainer>
			<Header>
				Email{' '}
				<Tooltip title={'Add Email'}>
					<PlusButton
						onClick={handleAddEmailContact}
						color={'primary'}
					>
						<AddIcon fontSize={'small'} />
					</PlusButton>
				</Tooltip>
			</Header>
			{fields.map((fullField, index) => (
				<PhoneContactContainer key={index}>
					<StyledGridContainer container spacing={2}>
						<Grid item xs={12} md={12}>
							<Controller
								name={`${name}.${index}.value`}
								control={control}
								rules={{
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: 'Invalid email address',
									},
								}}
								render={({ field }) => {
									return (
										<Input
											{...field}
											id={`${name}.${index}.value`}
											label={'Address'}
											fullWidth
											error={
												!!errors?.emailContacts?.[index]
											}
											helperText={
												errors?.emailContacts?.[index]
													?.message
											}
										/>
									);
								}}
							/>
						</Grid>
					</StyledGridContainer>
					{index !== 0 && (
						<DeleteButton
							color={'warning'}
							onClick={() => remove(index)}
							aria-label="delete"
						>
							<DeleteIcon />
						</DeleteButton>
					)}
				</PhoneContactContainer>
			))}
		</PhoneContactsContainer>
	);
};

const Header = styled.div`
	display: flex;
	align-items: center;
`;

const PlusButton = styled(IconButton)`
	margin-left: 5px;
`;

const DeleteButton = styled(IconButton)`
	height: max-content;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	max-width: 500px;
	overflow: auto;
	max-height: 500px;
	padding-top: 5px;
`;

const ButtonContainer = styled.div`
	margin-top: 30px;
	display: flex;
`;

const PhoneContactsContainer = styled.div`
	position: relative;
	overflow: visible;
`;

const PhoneContactContainer = styled(Paper)`
	position: relative;
	margin-top: 5px;
	overflow: visible;
`;

const NoTopMarginGrid = styled(Grid)`
	margin-top: 0 !important;
	padding-top: 0 !important;
`;

const StyledGridContainer = styled(Grid)`
	display: flex;
	align-items: center;
`;
