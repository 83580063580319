import React, { useEffect, useMemo, useState } from 'react';
import { updateUsersFilter } from '../../data/stores/companyStore';
import usePagination from '../../hooks/usePaginationStore';
import { fetchUsers } from '../../data/thunks/userThunk';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import EditIcon from '@mui/icons-material/Edit';
import { User, UserRoles } from '../../data/models/user';
import EditUserModal from './EditUserModal';
import Table from '../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';

export default function UsersTable() {
	const dispatch: AppDispatch = useDispatch();

	const { users } = useSelector((state: RootState) => state.companyState);

	const [editModal, setEditModal] = useState<false | User>(false);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination('companyState', 'users', updateUsersFilter);

	useEffect(() => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchUsers(filter));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'created',
			direction: 'DESC',
			page: 0,
			take: pageSize,
		};
		dispatch(fetchUsers(filter));
	};

	const handleSortOnChange = (order) => {
		setSortModel(order);
		const filter = {
			searchText,
			order: order[0]?.field,
			direction: order[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchUsers(filter));
	};

	const handleOnPageChange = (page, scrollToTop = false) => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		setPage(page);
		dispatch(fetchUsers(filter));
		if (scrollToTop) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const handleUserOnEdit = (id: string) => {
		const user = users.list.find((u) => u.id === id);
		if (user) {
			setEditModal(user);
		}
	};

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 200,
				disableColumnMenu: true,
			},
			{
				field: 'email',
				headerName: 'Email',
				minWidth: 200,
				disableColumnMenu: true,
			},
			{
				field: 'role',
				headerName: 'Role',
				disableColumnMenu: true,
				renderCell: (params) =>
					UserRoles[params.value]?.display || params.value,
			},
			{
				field: 'alwaysAddToProjects',
				headerName: 'Always Add to Projects',
				minWidth: 200,
				disableColumnMenu: true,
				flex: 1,
				renderCell: (params) => (params.value ? 'Yes' : 'No'),
			},
			{
				field: 'actions',
				headerName: '',
				disableColumnMenu: true,
				align: 'center',
				headerAlign: 'center',
				renderCell: (params: any) => (
					<IconButton
						onClick={() => handleUserOnEdit(params.id)}
						color={'primary'}
						aria-label="Edit User"
					>
						<EditIcon />
					</IconButton>
				),
			},
		];
	}, [handleUserOnEdit]);

	const rows = useMemo(() => {
		return users.list.map((user) => ({
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			email: user.email,
			role: user.role,
			alwaysAddToProjects: user.alwaysAddToProjects,
		}));
	}, [users.list]);

	return (
		<div>
			<EditUserModal
				open={!!editModal}
				user={editModal || undefined}
				handleClose={() => setEditModal(false)}
			/>
			<Table
				title={'User Management'}
				columns={columns}
				rows={rows}
				page={page}
				pageSize={pageSize}
				rowCount={users.count}
				sortModel={sortModel}
				onSortModelChange={handleSortOnChange}
				searchText={searchText}
				handleTextSearch={handleTextSearch}
				onPaginationModelChange={handleOnPageChange}
				loading={!users.initialized}
			/>
		</div>
	);
}
