import React from 'react';
import { styled } from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Input from './form/Input';

interface SearchBarProps {
	handleTextSearch: (searchText: string) => void;
	setSearchText: (searchText: string) => void;
	searchText: string;
	handleClear: () => void;
}
export default function SearchBar(props: SearchBarProps) {
	const { handleTextSearch, searchText, setSearchText, handleClear } = props;

	const handleOnKeyDown = (e) => {
		e.stopPropagation();
		if (e.keyCode == 13) {
			handleTextSearch(e.target.value);
		}
	};
	return (
		<StyledInputBase
			placeholder="Search…"
			variant="standard"
			inputProps={{ 'aria-label': 'search' }}
			onKeyDown={handleOnKeyDown}
			value={searchText}
			onChange={(e) => setSearchText(e.target.value)}
			InputProps={{
				startAdornment: <SearchIcon />,
				endAdornment: !!searchText ? (
					<StyledCloseIcon onClick={handleClear} />
				) : (
					<></>
				),
			}}
		/>
	);
}

const StyledInputBase = styled(Input)`
	color: inherit;
	& .MuiInputBase-input {
		padding: ${(props) => props.theme.spacing(1, 1, 1, 1)};
		padding-left: calc(1em + ${(props) => props.theme.spacing(4)}px);
		width: 100%;
	}
	& .MuiInput-underline:before {
		border-bottom: 1px solid ${(props) => props.theme.palette.text.divider};
	}
	& .MuiInput-underline:after {
		border-bottom: 1px solid ${(props) => props.theme.palette.text.divider};
	}
	& .MuiInput-underline:hover:before {
		border-bottom: 1px solid ${(props) => props.theme.palette.text.divider};
	}
`;

const StyledCloseIcon = styled(CloseIcon)`
	cursor: pointer;
`;
