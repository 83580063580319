import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import { useNavigate } from 'react-router-dom';
import Button from './common/buttons/Button';
import Config from '../config';
import { BackgroundBeams } from './ui/background-beams';
import { styled } from 'styled-components';
import AstronautImage from '../images/astronauts.jpeg';
import Paper from './common/layout/Paper';
import Link from './common/Link';
import AstroIcon from '../images/AstroLogo.svg';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

export default function LoginScreen() {
	const navigate = useNavigate();
	const authState = useSelector((state: RootState) => state.authState);

	useEffect(() => {
		if (authState.astroToken) {
			navigate('/');
		}
	}, [authState, navigate]);
	const SCOPES =
		'email profile https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents'; // Add other scopes as needed

	const handleLogin = () => {
		const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
			Config.googleOAuth.GOOGLE_CLIENT_ID
		)}&redirect_uri=${encodeURIComponent(
			Config.googleOAuth.REDIRECT_URI
		)}&scope=${encodeURIComponent(
			SCOPES
		)}&access_type=offline&prompt=consent`;
		window.location.href = authUrl;
	};

	return (
		<Container>
			{/* <BackgroundBeams /> */}
			{/* <ImageContainer></ImageContainer> */}
			<LoginContainer>
				<LoginBox>
					<AstroLogoContainer>
						<img src={AstroIcon} style={{ height: 50 }} />
						<AstroText>Astro</AstroText>
					</AstroLogoContainer>
					<SubText>Tools for frictionless filmmaking</SubText>
					<SubText2>
						Create, manage, and control projects all in one place
					</SubText2>
					<Button style={{ width: '100%' }} onClick={handleLogin}>
						Login
					</Button>
				</LoginBox>
			</LoginContainer>
			<div
				style={{ position: 'absolute', bottom: 15, display: 'inline' }}
			>
				<Link
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 12,
					}}
					onClick={() =>
						window.open(
							`${Config.baseUrl}/privacy-policy`,
							'_blank'
						)
					}
				>
					View our Privacy Policy <StyledOpenInNewOutlinedIcon />
				</Link>
				<BottomText>
					Astro Filmmaker is a state of the art web based platform
					utilizing best in class film production workflows all
					powered through Google Workspace APIs. No user data obtained
					through Google APIs are used to develop, improve or train
					generalized AI and/or ML tools. No data is shared or sold to
					any external entity.
				</BottomText>
			</div>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
`;

const InfoText = styled.div`
	position: absolute;
	bottom: -100px;
	font-size: 12px;
	left: 0px;
`;

const BottomText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	color: ${({ theme }) => theme.palette.text.label};
`;

const ImageContainer = styled.div`
	flex: 1;
	background-image: url(${AstronautImage});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const LoginBox = styled(Paper)`
	padding-top: ${({ theme }) => theme.spacing(6)};
	padding-bottom: ${({ theme }) => theme.spacing(6)};
	padding-right: ${({ theme }) => theme.spacing(5)};
	padding-left: ${({ theme }) => theme.spacing(5)};
	position: absolute !important;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	position: relative;
	width: 380px;
`;

const AstroLogoContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const AstroText = styled.div`
	color: ${({ theme }) => theme.palette.text.primary};
	font-size: 3rem;
	margin-left: ${({ theme }) => theme.spacing(2)};
`;

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
	margin-left: ${({ theme }) => theme.spacing(1)};
	font-size: 14px !important;
`;

const SubText = styled.div`
	margin-top: ${({ theme }) => theme.spacing(2)};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const SubText2 = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	color: ${({ theme }) => theme.palette.text.label};
`;
