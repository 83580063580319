import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { AppDispatch, RootState } from '../../data/store';
import { logout } from '../../data/stores/authStore';
import Menu from '../common/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '../common/Avatar';

export default function UserNavItem() {
	const dispatch = useDispatch<AppDispatch>();
	const authState = useSelector((state: RootState) => state.authState);
	const navigate = useNavigate();

	return (
		<UserContainer>
			<Menu
				// title={`${authState.user?.firstName} ${authState.user?.lastName}`}
				iconButton
				alternativeIcon={() => (
					<StyledAvatar>
						{authState.user?.firstName[0]}
						{authState.user?.lastName[0]}
					</StyledAvatar>
				)}
				menuItems={[
					{
						display: (
							<div>
								{authState.user?.firstName}{' '}
								{authState.user?.lastName}
								<UserEmail>{authState.user?.email}</UserEmail>
							</div>
						),
					},
					{ display: 'DIVIDER' },
					// {
					// 	display: (
					// 		<DropDownItem>
					// 			<PersonIcon />
					// 			Profile
					// 		</DropDownItem>
					// 	),
					// 	onClick: () => navigate('/profile'),
					// },
					{
						display: (
							<DropDownItem>
								<BusinessIcon />
								My Company
							</DropDownItem>
						),
						onClick: () => navigate('/company'),
					},
					{
						display: (
							<DropDownItem>
								<SettingsIcon />
								Settings
							</DropDownItem>
						),
						onClick: () => navigate('/settings'),
					},
					{ display: 'DIVIDER' },
					{
						display: (
							<DropDownItem>
								<LogoutIcon />
								Sign out
							</DropDownItem>
						),
						onClick: () => dispatch(logout()),
					},
				]}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			/>
		</UserContainer>
	);
}

const UserContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const DropDownItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const UserEmail = styled.div`
	font-size: 0.8rem;
	color: ${({ theme }) => theme.palette.text.subheader};
`;

const StyledAvatar = styled(Avatar)`
	font-size: 14px !important;
	height: 30px !important;
	width: 30px !important;
`;
