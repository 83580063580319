import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../data/store';
import { loginSuccess } from '../data/stores/authStore';
import { baseAxiosClient } from '../clients/axios-client';
import { User } from '../data/models/user';
import ScreenContainer from './common/layout/ScreenContainer';
import CenterContainer from './common/layout/CenterContainer';
import Config from '../config';

export default function OAuth2CallbackScreen() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		// Extract the code from URL query parameters
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get('code');

		const handleOAuth2Callback = async (code: string) => {
			const response: { user: User; astroToken: string } =
				await baseAxiosClient({
					url: `${Config.apiBaseUrl}/user/oauth2callback`,
					method: `POST`,
					data: {
						code,
					},
				});
			console.log('response', response);
			dispatch(
				loginSuccess({
					user: response.user,
					astroToken: response.astroToken,
				})
			);
			navigate('/');
		};
		if (code) {
			handleOAuth2Callback(code);
		}
	}, [location, navigate, dispatch]);

	return (
		<ScreenContainer>
			<CenterContainer>
				<h2>Getting the Astro Universe ready for you...</h2>
			</CenterContainer>
		</ScreenContainer>
	);
}
