import { AxiosResponse } from 'axios';
import {
	UpdateSelfDTO,
	User,
	UserDTO,
	UserUpdateDTO,
	UsersFilter,
} from '../data/models/user';
import axiosClient from '../clients/axios-client';
import { stringifyReqObj } from '../utils/common-utils';

export async function createUser(user: UserDTO): Promise<User> {
	const graphqlQuery = `
            mutation {
                createUser(
                    data: ${stringifyReqObj(user)},
                ) {
                    ${userROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createUser;
}

export async function fetchSelf(): Promise<User> {
	const graphqlQuery = `
            {
                fetchSelf {
                    ${userROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.fetchSelf;
}

export async function updateUser(user: UserUpdateDTO): Promise<User> {
	const graphqlQuery = `
            mutation {
                updateUser(
                    data: ${stringifyReqObj(user)},
                ) {
                    ${userROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateUser;
}

export async function updateSelf(user: UpdateSelfDTO): Promise<User> {
	const graphqlQuery = `
            mutation {
                updateSelf(
                    data: ${stringifyReqObj(user)},
                ) {
                    ${userROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateSelf;
}

export async function fetchUsers(
	filter: UsersFilter = {}
): Promise<{ list: User[]; count: number }> {
	const graphqlQuery = `
            {
                users(
                    filter: ${stringifyReqObj(filter)},
                ) {
                    list {
						${userROGraphQl}
					}
					count
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.users;
}

export async function login(
	credential: string
): Promise<{ user: User; astroToken: string }> {
	const graphqlQuery = `
            mutation {
                login(
                    credential: "${credential}",
                ) {
                    astroToken
                    user {
                        ${userROGraphQl}
                    }
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.login;
}

export async function connectQuickBooksRedirect(): Promise<string> {
	const graphqlQuery = `
            mutation {
                connectQuickBooksRedirect
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.connectQuickBooksRedirect;
}

export async function fetchMail(): Promise<any> {
	const graphqlQuery = `
            {
                mail
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.mail;
}

const userROGraphQl = `
    id
    email
    firstName
    lastName
	role
	alwaysAddToProjects
    acceptedInvite
	acceptedTermsAndConditions
    googleId
	preferences {
		theme
	}
	created
	updated
`;
