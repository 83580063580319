// authReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncStage } from '../reduxCommon';
import { Company, CompanyUsers } from '../models/company';
import { User } from '../models/user';
import findIndex from 'lodash/findIndex';

interface CompanyState {
	stage: AsyncStage;
	error: string;
	company: Company;
	users: CompanyUsers;
}

const initialState: CompanyState = {
	stage: AsyncStage.NoOp,
	error: '',
	company: {
		initialized: false,
		id: '',
		created: '',
		updated: '',
		name: '',
		domain: '',
		quickLinks: [],
		templates: [],
		quickBooksTimeConnection: {
			connectedBy: {
				id: '',
				name: '',
				date: '',
			},
			accessToken: '',
			lastFetched: '',
			failure: false,
			lastFailureReason: '',
			qbInfoSheets: [],
		},
	},
	users: {
		initialized: false,
		count: 0,
		list: [],
		filter: {
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'name', sort: 'asc' }],
			onlyShowUnused: true,
			where: {},
		},
	},
};

const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		asyncCallStart: (state: CompanyState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: CompanyState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: CompanyState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		refreshCompanySuccess: (
			state: CompanyState,
			action: PayloadAction<Company>
		) => {
			state.company = { ...action.payload, initialized: true };
			state.stage = AsyncStage.Success;
		},
		updateCompanySuccess: (
			state: CompanyState,
			action: PayloadAction<Company>
		) => {
			state.company = {
				...state.company,
				...action.payload,
			};
			state.stage = AsyncStage.Success;
		},
		fetchUsersSuccess: (
			state: CompanyState,
			action: PayloadAction<{ list: User[]; count: number }>
		) => {
			state.users = {
				...state.users,
				initialized: true,
				list: action.payload.list,
				count: action.payload.count,
			};
			state.stage = AsyncStage.Success;
		},
		updateUserSuccess: (
			state: CompanyState,
			action: PayloadAction<User>
		) => {
			const list = [...state.users.list];
			const user = action.payload;
			const userIndex = findIndex(list, (p) => p.id === user.id);
			list[userIndex] = user;
			state.users = {
				...state.users,
				list,
			};
			state.stage = AsyncStage.Success;
		},
		updateUsersFilter: (
			state: CompanyState,
			action: PayloadAction<any>
		) => {
			state.users = {
				...state.users,
				filter: {
					...state.users.filter,
					...action.payload,
				},
			};
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshCompanySuccess,
	updateCompanySuccess,
	fetchUsersSuccess,
	updateUserSuccess,
	updateUsersFilter,
} = companySlice.actions;

export default companySlice.reducer;
