// authReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncStage } from '../reduxCommon';
import { Client, Clients } from '../models/client';

interface ClientState {
	stage: AsyncStage;
	error: string;
	client: Client;
	clients: Clients;
}

const initialState: ClientState = {
	stage: AsyncStage.NoOp,
	error: '',
	client: {
		id: '',
		projects: [],
		contacts: [],
		created: '',
		updated: '',
		createdBy: {
			id: '',
			name: '',
			date: '',
		},
		name: '',
	},
	clients: {
		list: [],
		count: 0,
		filter: {
			searchText: '',
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'created', sort: 'desc' }],
			where: {},
		},
		initialized: false,
	},
};

const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		asyncCallStart: (state: ClientState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: ClientState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: ClientState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		refreshClientSuccess: (
			state: ClientState,
			action: PayloadAction<Client>
		) => {
			state.client = action.payload;
			state.stage = AsyncStage.Success;
		},
		fetchClientsSuccess: (
			state: ClientState,
			action: PayloadAction<{ list: Client[]; count: number }>
		) => {
			state.clients = {
				...state.clients,
				list: action.payload.list,
				count: action.payload.count,
				initialized: true,
			};
			state.stage = AsyncStage.Success;
		},
		updateClientsFilter: (
			state: ClientState,
			action: PayloadAction<any>
		) => {
			state.clients = {
				...state.clients,
				filter: {
					...state.clients.filter,
					...action.payload,
				},
			};
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshClientSuccess,
	fetchClientsSuccess,
	updateClientsFilter,
} = clientSlice.actions;

export default clientSlice.reducer;
