import React, { useEffect, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import Table from '../../common/Table';
import dayjs from 'dayjs';
import { styled } from 'styled-components';
import Paper, { PaperHeader } from '../../common/layout/Paper';
import Button from '../../common/buttons/Button';
import { showModal } from '../../../data/stores/modalStore';
import Add from '@mui/icons-material/Add';
import AddClientContactModal from './AddClientContactModal';

export default function ClientContactsTable() {
	const dispatch: AppDispatch = useDispatch();

	const {
		client: { contacts },
	} = useSelector((state: RootState) => state.clientState);
	console.log('contacts', contacts);

	const [sortModel, setSortModel] = useState([
		{ field: 'created', sort: 'desc' },
	]);

	// const [editModal, setEditModal] = useState<false | User>(false);

	// const {
	// 	searchText,
	// 	setSearchText,
	// 	page,
	// 	setPage,
	// 	pageSize,
	// 	sortModel,
	// 	setSortModel,
	// 	where,
	// 	setWhere,
	// } = usePagination('companyState', 'users', updateUsersFilter);

	useEffect(() => {
		// const filter = {
		// 	searchText,
		// 	order: sortModel[0]?.field,
		// 	direction: sortModel[0]?.sort,
		// 	page: page,
		// 	take: pageSize,
		// 	where,
		// };
		// dispatch(fetchUsers(filter));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		// setSearchText(newSearchText);
		// setPage(0);
		// const filter = {
		// 	searchText: newSearchText,
		// 	order: 'created',
		// 	direction: 'DESC',
		// 	page: 0,
		// 	take: pageSize,
		// };
		// dispatch(fetchUsers(filter));
	};

	const handleSortOnChange = (order) => {
		// setSortModel(order);
		// const filter = {
		// 	searchText,
		// 	order: order[0]?.field,
		// 	direction: order[0]?.sort,
		// 	page: page,
		// 	take: pageSize,
		// 	where,
		// };
		// dispatch(fetchUsers(filter));
	};

	const handleOnPageChange = (page, scrollToTop = false) => {
		// const filter = {
		// 	searchText,
		// 	order: sortModel[0]?.field,
		// 	direction: sortModel[0]?.sort,
		// 	page: page,
		// 	take: pageSize,
		// 	where,
		// };
		// setPage(page);
		// dispatch(fetchUsers(filter));
		// if (scrollToTop) {
		// 	window.scrollTo({ top: 0, behavior: 'smooth' });
		// }
	};

	const handleUserOnEdit = (contactUuid) => {
		console.log('contactUuid', contactUuid);
		const contact = contacts.find((c) => c.uuid === contactUuid);
		console.log('contact', contact);
		dispatch(
			showModal({ openId: 'addClientContactModal', props: { contact } })
		);
	};

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 200,
				disableColumnMenu: true,
			},
			// {
			// 	field: 'email',
			// 	headerName: 'Email',
			// 	minWidth: 200,
			// 	disableColumnMenu: true,
			// },
			// {
			// 	field: 'role',
			// 	headerName: 'Role',
			// 	disableColumnMenu: true,
			// 	renderCell: (params) =>
			// 		UserRoles[params.value]?.display || params.value,
			// },
			{
				field: 'created',
				headerName: 'Created',
				minWidth: 120,
				disableColumnMenu: true,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, YYYY'),
			},
			{
				field: 'actions',
				headerName: '',
				disableColumnMenu: true,
				align: 'center',
				headerAlign: 'center',
				renderCell: (params: any) => (
					<IconButton
						onClick={() => {
							console.log('params', params);
							handleUserOnEdit(params.row.uuid);
						}}
						color={'primary'}
						aria-label="Edit User"
					>
						<EditIcon />
					</IconButton>
				),
			},
		];
	}, [handleUserOnEdit]);

	const rows = useMemo(() => {
		return contacts.map((user) => ({
			id: user.uuid,
			uuid: user.uuid,
			name: `${user.firstName} ${user.lastName}`,
			created: user.created,
			// email: user.email,
			// role: user.role,
		}));
	}, [contacts]);

	if (!contacts.length) {
		return (
			<NoContactsContainer>
				<PaperHeader>Contacts</PaperHeader>
				<NoContactsInnerContainer>
					<NoContactsText>
						Client Contacts have not been added
					</NoContactsText>
					<Button
						onClick={() =>
							dispatch(
								showModal({ openId: 'addClientContactModal' })
							)
						}
					>
						ADD CONTACT
					</Button>
				</NoContactsInnerContainer>
			</NoContactsContainer>
		);
	}

	return (
		<div>
			{/* <EditUserModal
				open={!!editModal}
				user={editModal || undefined}
				handleClose={() => setEditModal(false)}
			/> */}
			<Table
				title={'Contacts'}
				columns={columns}
				rows={rows}
				page={0}
				pageSize={50}
				rowCount={contacts.length}
				handleAdd={() =>
					dispatch(showModal({ openId: 'addClientContactModal' }))
				}
				// @ts-ignore
				sortModel={sortModel}
				// sortModel={[{ field: 'created', sort: 'desc' }]}
				onSortModelChange={handleSortOnChange}
				// searchText={searchText}
				// handleTextSearch={handleTextSearch}
				onPaginationModelChange={handleOnPageChange}
				// loading={!users.initialized}
				paginationMode={'client'}
				// boxSx={{ height: 'calc(100vh - 250px)' }}
				boxSx={{ height: 'auto' }}
				hideFooter
				autoHeight
			/>
		</div>
	);
}

const NoContactsContainer = styled(Paper)`
	padding: 9px 20px 20px 10px;
`;

const NoContactsInnerContainer = styled.div`
	text-align: center;
`;

const NoContactsText = styled.div`
	margin-bottom: 10px;
`;
