import React from 'react';
import { styled } from 'styled-components';

export default function ScreenContainer({
	children,
}: {
	children: React.ReactNode;
}) {
	return <StyledContainer>{children}</StyledContainer>;
}

const StyledContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	overflow: scroll;
	height: 100%;
`;
