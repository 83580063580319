import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { styled } from 'styled-components';
import { HeaderContainer, HeaderTitle } from './layout/PageHeader';
import SearchBar from './SearchBar';
import AddIcon from '@mui/icons-material/Add';
import { PaperHeader } from './layout/Paper';
import { TableFilterOption } from './Table';
import Autocomplete from './form/AutoComplete';

interface TableToolbarProps {
	title?: string;
	searchText?: string;
	handleTextSearch?: (localSearchText: string) => void;
	handleAdd?: () => void;
	filterOptions?: TableFilterOption[];
}

const TableToolbar: React.FC<TableToolbarProps> = (
	props: TableToolbarProps
) => {
	const {
		title = '',
		searchText = '',
		handleTextSearch,
		handleAdd,
		filterOptions,
	} = props;

	const [localSearchText, setLocalSearchText] = useState(searchText);

	const handleSearch = () => {
		handleTextSearch && handleTextSearch(localSearchText);
	};

	const handleClear = () => {
		setLocalSearchText('');
		handleTextSearch && handleTextSearch('');
	};

	return (
		<StyledGridToolbarContainer>
			<HeaderContainer>
				{title && <TableHeader>{title}</TableHeader>}
				{handleAdd && (
					<IconButtonContainer>
						<IconButton onClick={handleAdd} color={'primary'}>
							<AddIcon />
						</IconButton>
					</IconButtonContainer>
				)}
				<HeaderRightContainer>
					{!!handleTextSearch && (
						<Box
							sx={{
								width: 300,
								marginLeft: 2,
								marginRight: 2,
							}}
						>
							<SearchBar
								searchText={localSearchText}
								setSearchText={setLocalSearchText}
								handleTextSearch={handleSearch}
								handleClear={handleClear}
							/>
						</Box>
					)}
					<GridToolbarExport />
				</HeaderRightContainer>
			</HeaderContainer>
			<FiltersContainer>
				{filterOptions?.map((filterOption) => (
					<AutoCompleteContainer>
						<Autocomplete
							key={filterOption.field}
							label={filterOption.label}
							value={filterOption.value}
							options={filterOption.options}
							onChange={(event, value) => {
								filterOption.onChange(
									filterOption.field,
									value
								);
							}}
							InputLabelProps={{ shrink: !!filterOption.value }}
						/>
					</AutoCompleteContainer>
				))}
			</FiltersContainer>
		</StyledGridToolbarContainer>
	);
};

export const TableHeader = styled.div`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 24px;
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
	display: block !important;
	padding: 10px 10px 0 10px !important;
`;

const HeaderRightContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`;

const IconButtonContainer = styled.div`
	margin-left: 10px;
`;

const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
`;

const AutoCompleteContainer = styled.div`
	width: 175px;
	margin-right: 10px;
`;

export default TableToolbar;
