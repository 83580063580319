import React from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
// import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import styled from 'styled-components';

interface CustomProps {
	mask: 'phone' | 'currency';
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const MaskInput = React.forwardRef<HTMLElement, CustomProps>(function MaskInput(
	props,
	ref
) {
	const { onChange, mask, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask={masks[mask].mask}
			unmask
			definitions={masks[mask].definitions}
			blocks={masks[mask].blocks}
			// @ts-ignore
			inputRef={ref}
			onAccept={(value: any) =>
				onChange({ target: { name: props.name, value } })
			}
			overwrite
		/>
	);
});

interface TextMaskProps {
	id: string;
	name: string;
	onChange: (e: any) => void;
	value: string;
	label: string;
	mask?: 'phone' | 'currency';
	error?: boolean;
	helperText?: string;
	fullWidth?: boolean;
	startAdornment?: any;
	inputProps?: any;
	disabled?: boolean;
	required?: boolean;
}
const TextMask = React.forwardRef((props: TextMaskProps, ref) => {
	const {
		name,
		onChange,
		value,
		label,
		error,
		disabled,
		startAdornment,
		inputProps,
		helperText,
		fullWidth = true,
		mask = 'phone',
		required,
		...rest
	} = props;

	return (
		<Box>
			<TextField
				{...rest}
				fullWidth={fullWidth}
				label={label}
				value={value}
				onChange={onChange}
				name={name}
				inputRef={ref}
				id="formatted-text-mask-input"
				InputProps={{
					inputComponent: MaskInput as any,
					disabled: disabled,
					inputProps: { ...inputProps, mask },
					startAdornment: startAdornment,
				}}
				error={error}
				helperText={error ? helperText : undefined}
				required={required}
				variant="outlined"
				size="small"
				InputLabelProps={{ shrink: true }}
			/>
		</Box>
	);
});

export default TextMask;

const masks = {
	phone: {
		mask: '(#00) 000-0000',
		definitions: {
			'#': /[1-9]/,
		},
		blocks: {},
	},
	currency: {
		mask: '$num',
		definitions: {},
		blocks: {
			num: {
				mask: Number,
				thousandsSeparator: ',',
				scale: 2,
				radix: '.',
				mapToRadix: ['.'],
			},
		},
	},
};

const HelperText = styled.span`
	font-size: 0.75rem;
	color: #d32f2f;
`;
